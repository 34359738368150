/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/styles/global.css';
import './src/styles/custom.css';
import './src/styles/blobz.min.css';
import React from 'react';
import { QueryClientProvider } from 'react-query';
import { Helmet } from 'react-helmet';
import { Toaster } from 'react-hot-toast';
import guardianQueryClient from '@src/apiFetch/guardianQueryClient';
import { AuthProvider } from '@descope/react-sdk';
import getConfig from '@src/util/getConfig';
import ZipyProvider from '@src/components/thirdPartyProviders/Zipy/ZipyProvider';
import SentryProvider from '@src/components/thirdPartyProviders/Sentry/SentryProvider';
import { EmbeddedChat } from '@embeddedchat/react';

export const wrapRootElement = ({ element }) => {
    const config = getConfig();
    return (
        // eslint-disable-next-line react/jsx-filename-extension
        <QueryClientProvider client={guardianQueryClient}>
            <Helmet
                bodyAttributes={{ class: 'font-custom' }}
                link={[
                    {
                        rel: 'icon',
                        type: 'image/svg',
                        href: config.favicon_url
                    }
                ]}
            >
                <script
                    async
                    src={`https://www.googletagmanager.com/gtag/js?id=${config.google_analytics_id}`}
                />
                <script>
                    {` window.dataLayer = window.dataLayer || []
                  function gtag() {
                    dataLayer.push(arguments)
                  }
                  gtag('js', new Date())
                  gtag('config', '${config.google_analytics_id}')`}
                </script>
            </Helmet>
            {element}
            <div
                dangerouslySetInnerHTML={{__html: `<!-- Start of Rocket.Chat Livechat Script -->
\t<script type="text/javascript">
\t(function(w, d, s, u) {
\t\tw.RocketChat = function(c) { w.RocketChat._.push(c) }; w.RocketChat._ = []; w.RocketChat.url = u;
\t\tvar h = d.getElementsByTagName(s)[0], j = d.createElement(s);
\t\tj.async = true; j.src = 'https://chat.gate-serv.net/livechat/rocketchat-livechat.min.js?_=201903270000';
\t\th.parentNode.insertBefore(j, h);
\t})(window, document, 'script', 'https://chat.gate-serv.net/livechat');
\t</script>`}}
            />
        </QueryClientProvider>
    );
};

export const wrapPageElement = ({ element }) =>
    (
        <>
            <AuthProvider projectId={getConfig().descope_project_id}>
                <ZipyProvider />
                <SentryProvider />
                {element}
            </AuthProvider>
            <Toaster toastOptions={{ duration: 5000 }} />
        </>
    );

// Prevent page scrolling on url search parameters change
export const shouldUpdateScroll = () => {
    return false;
};
